import { EXTENDED_DATA_SCHEMA_TYPES } from './types';
import { getFieldValue } from './fieldHelpers';
import { ASSOCIATED } from './listingsHelpers';

export const GROUPS_NAME = 'teams';
export const GROUP_SINGULAR_NAME = 'team';
export const INVITED_NAME = 'team';
export const STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
  INACTIVE: 'inactive',
  REJECTED: 'rejected',
  REVOKED: 'revoked',
}

export const USER_TYPES = {
  PROVIDER: 'provider',
  BUYER: 'buyer'
};

/**
 * Get the namespaced attribute key based on the specified extended data scope and attribute key
 * @param {*} scope extended data scope
 * @param {*} key attribute key in extended data
 * @returns a string containing the namespace prefix and the attribute name
 */
export const addScopePrefix = (scope, key) => {
  const scopeFnMap = {
    private: k => `priv_${k}`,
    protected: k => `prot_${k}`,
    public: k => `pub_${k}`,
    meta: k => `meta_${k}`,
  };

  const validKey = key.replace(/\s/g, '_');
  const keyScoper = scopeFnMap[scope];

  return !!keyScoper ? keyScoper(validKey) : validKey;
};

/**
 * Pick extended data fields from given form data.
 * Picking is based on extended data configuration for the user and target scope and user type.
 *
 * This expects submit data to be namespaced (e.g. 'pub_') and it returns the field without that namespace.
 * This function is used when form submit values are restructured for the actual API endpoint.
 *
 * Note: This returns null for those fields that are managed by configuration, but don't match target user type.
 *       These might exists if user swaps between user types before saving the user.
 *
 * @param {Object} data values to look through against userConfig.js and util/configHelpers.js
 * @param {String} targetScope Check that the scope of extended data the config matches
 * @param {String} targetUserType Check that the extended data is relevant for this user type.
 * @param {Object} userFieldConfigs Extended data configurations for user fields.
 * @returns Array of picked extended data fields from submitted data.
 */
export const pickUserFieldsData = (data, targetScope, targetUserType, userFieldConfigs) => {
  return userFieldConfigs.reduce((fields, field) => {
    const { key, userTypeConfig, scope = 'public', schemaType } = field || {};
    const namespacedKey = addScopePrefix(scope, key);

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetScope = scope === targetScope;
    const isTargetUserType =
      !userTypeConfig.limitToUserTypeIds || userTypeConfig.userTypeIds.includes(targetUserType);

    if (isKnownSchemaType && isTargetScope && isTargetUserType) {
      const fieldValue = getFieldValue(data, namespacedKey);
      return { ...fields, [key]: fieldValue };
    } else if (isKnownSchemaType && isTargetScope && !isTargetUserType) {
      // Note: this clears extra custom fields
      // These might exists if user swaps between user types before saving the user.
      return { ...fields, [key]: null };
    }
    return fields;
  }, {});
};

export const pickSellerData = (data, targetUserType) => {
  if (targetUserType == USER_TYPES.PROVIDER) {
    const {
      // phone,
      businessName,
      businessPrimaryPhone,
      businessWebsite,
      expectedItems,
      sports,
      sportsAdditionalData = '',
      conditions,
      businessLocation,

      frfname, frlname, frBusinessRelationship, frPhone, frEmail,
      srfname, srlname, srBusinessRelationship, srPhone, srEmail,
    } = data || {};

    return {
      // phone,
      businessName,
      businessPrimaryPhone,
      businessWebsite,
      expectedItems,
      sports,
      sportsAdditionalData,
      conditions,
      businessLocation,

      frfname, frlname, frBusinessRelationship, frPhone, frEmail,
      srfname, srlname, srBusinessRelationship, srPhone, srEmail,
    };
  }

  return {};
};

export const updateRest = (rest, properties) => {
  return Object.keys(rest).reduce((acc, key) => {
    if (!properties.hasOwnProperty(key)) {
      acc[key] = rest[key];
    }
    return acc;
  }, {});
};

/**
 * Pick extended data fields from given extended data of the user entity.
 * Picking is based on extended data configuration for the user and target scope and user type.
 *
 * This returns namespaced (e.g. 'pub_') initial values for the form.
 *
 * @param {Object} data extended data values to look through against userConfig.js and util/configHelpers.js
 * @param {String} targetScope Check that the scope of extended data the config matches
 * @param {String} targetUserType Check that the extended data is relevant for this user type.
 * @param {Object} userFieldConfigs Extended data configurations for user fields.
 * @returns Array of picked extended data fields
 */
export const initialValuesForUserFields = (data, targetScope, targetUserType, userFieldConfigs) => {
  return userFieldConfigs.reduce((fields, field) => {
    const { key, userTypeConfig, scope = 'public', schemaType } = field || {};
    const namespacedKey = addScopePrefix(scope, key);

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetScope = scope === targetScope;
    const isTargetUserType =
      !userTypeConfig?.limitToUserTypeIds || userTypeConfig?.userTypeIds?.includes(targetUserType);

    if (isKnownSchemaType && isTargetScope && isTargetUserType) {
      const fieldValue = getFieldValue(data, key);
      return { ...fields, [namespacedKey]: fieldValue };
    }
    return fields;
  }, {});
};

/**
 * Returns props for custom user fields
 * @param {*} userFieldsConfig Configuration for user fields
 * @param {*} intl
 * @param {*} userType User type to restrict fields to. If none is passed,
 * only user fields applying to all user types are returned.
 * @param {*} isSignup Optional flag to determine whether the target context
 * is a signup form. Defaults to true.
 * @returns an array of props for CustomExtendedDataField: key, name,
 * fieldConfig, defaultRequiredMessage
 */
export const getPropsForCustomUserFieldInputs = (
  userFieldsConfig,
  intl,
  userType = null,
  isSignup = true
) => {
  return (
    userFieldsConfig?.reduce((pickedFields, fieldConfig) => {
      const { key, userTypeConfig, schemaType, scope, saveConfig = {} } = fieldConfig || {};
      const namespacedKey = addScopePrefix(scope, key);
      const showField = isSignup ? saveConfig.displayInSignUp : true;

      const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
      const isTargetUserType =
        !userTypeConfig?.limitToUserTypeIds || userTypeConfig?.userTypeIds?.includes(userType);
      const isUserScope = ['public', 'private', 'protected'].includes(scope);

      return isKnownSchemaType && isTargetUserType && isUserScope && showField
        ? [
            ...pickedFields,
            {
              key: namespacedKey,
              name: namespacedKey,
              fieldConfig: fieldConfig,
              defaultRequiredMessage: intl.formatMessage({
                id: 'CustomExtendedDataField.required',
              }),
            },
          ]
        : pickedFields;
    }, []) || []
  );
};

const getApprovedSellerProp = (currentUser, prop, redirect, history) => {
  let isApproved = false;
  let sellerTypes = ['provider', 'provider-customer'];
  let { publicData } = currentUser?.attributes?.profile || {};
  let { userType } = publicData || {};
  if (sellerTypes.includes(userType)) {
    isApproved = publicData[prop] || false;
  }

  if (!!currentUser?.attributes?.profile?.publicData && !isApproved && redirect && history !== null) {
    //history.push('/');
  }
  return isApproved;
};

export const isApprovedSeller = (currentUser, redirect = false, history = null) => {
  return getApprovedSellerProp(currentUser, 'approved_seller', redirect, history);
};

export const isApprovedOwner = (currentUser, redirect = false, history = null) => {
  return getApprovedSellerProp(currentUser, 'approved_owner', redirect, history);
}

export const hasGroups = (currentUser) => {
  const AVAILABLE_GROUPS_STATUS = ['pending', 'active'];
  let { publicData } = currentUser?.attributes?.profile || {};
  let groups = publicData && publicData[GROUPS_NAME] ? publicData[GROUPS_NAME] : [];
  if (groups && Array.isArray(groups) && groups.length) {
    let activeGroups = groups.filter(g => AVAILABLE_GROUPS_STATUS.includes(g.status));
    return !!activeGroups.length;
  }

  return false;
}

export const prepareMemberData = (authorUser, creatorUser, creatorType, reviews = [], isAssociated) => {
  let authorObj = authorUser;
  let authorType = 'own'; // 'own', 'owner', 'associated
  if (isAssociated) {
    authorType = 'owner';

    if (creatorType == ASSOCIATED) {
      authorType = ASSOCIATED;
      authorObj = creatorUser;

      reviews = creatorUser?.attributes?.profile?.publicData?.reviews || [];
    }
  }

  let ratingCounter = reviews.length;
  let totalRating = reviews.reduce((sum, review) => sum + parseInt(review?.rating || review?.attributes?.rating || 0, 10), 0);
  if (isNaN(totalRating)) {
    totalRating = 1;
  }
  let rating = ratingCounter && !isNaN(totalRating) ? Math.ceil(totalRating / ratingCounter) : 1;

  let authorId = authorObj?.id?.uuid;
  let authorName = authorObj?.attributes?.profile?.displayName;

  return { authorObj, authorId, authorName, rating, ratingCounter, authorType, reviews };
};
